import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import {Grid, Typography, Paper, Link as MyLink} from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

import CardLink from '../components/CardLink';
import Button from '../components/Button';
import {steps} from '../utils/tour';
import {updateTourCompletedStatus} from '../utils/api';
import {useUser} from '../utils/UserSession';
import IconLink from "../components/IconLink";

const Dashboard = () => {
  const {user, setUser} = useUser();
  //const [runTour, setRunTour] = false;
  /* Rimosso Tour il 12.01.2022 */
  const [runTour, setRunTour] = useState(!user.hasCompletedTour);

  const onComplete = useCallback(tour => {
    if (tour.action === 'reset') {
      updateTourCompletedStatus();
    }
    if (tour.action === 'close') {
      setRunTour(false);
    }
  }, []);


  //Se il member è anche un docente e non ha ancora selezionato il pulsantone di riferimento, allora mostro i due pulsantoni
  const showSectionSelection = (user.memberIsDocente && user.docenteIframe && !localStorage.getItem('sectionSelected'));


  if (showSectionSelection) {
    return <>
            <Container>
                <GridItemLarge id="tour-step-docenti-1">
                    <CardLink
                        to="/grp-docenti"
                        text="Profilo accompagnatore"
                        background="#69b9ff"
                        icon="School"
                        onClick={() => localStorage.setItem('sectionSelected', 'docente')}
                    />
                    <Typography variant="h6" gutterBottom component="div" className="grid-padding">
                        Accompagnerai i tuoi studenti per una magnifica esperienza insieme.
                    </Typography>
                </GridItemLarge>
                <GridItemLarge id="tour-step-docenti-2">
                    <CardLink
                        to="/dashboard"
                        text="Profilo cliente"
                        background="#69b9ff"
                        icon="School"
                        onClick={() => localStorage.setItem('sectionSelected', 'member')}
                    />
                    <Typography variant="h6" gutterBottom component="div" className="grid-padding">
                        Accedi all'area cliente
                    </Typography>
                </GridItemLarge>
            </Container>
        </>;
  }

  return (
    <>
      {/*<Joyride
        run={runTour}
        locale={{next: 'Avanti', last: 'Fatto'}}
        continuous
        hideBackButton
        disableOverlayClose
        showProgress
        callback={onComplete}
        steps={steps}
        spotlightPadding={4}
        styles={{
          options: {
            primaryColor: '#3192ec',
          },
        }}
      /> */}
      <Container>
        <GridItem id="tour-step-3">
          <CardLink
            to="/account"
            text="Informazioni account"
            background="#FB8B01"
            icon="Member"
          />
          <Typography variant="h6" gutterBottom component="div" className="grid-padding">
            Qui potrai trovare tutte le informazioni relative alla tua anagrafica cliente personale.
          </Typography>
        </GridItem>

        <GridItem id="tour-step-4">
          <CardLink
            to="/practices"
            text="Prenotazioni"
            background="#3F50BA"
            icon="Practices"
          />
          <Typography variant="h6" gutterBottom component="div" className="grid-padding">
            Qui potrai visualizzare tutte le tue prenotazioni e controllare il loro stato.
          </Typography>
        </GridItem>

        <GridItem id="tour-step-5">
          <CardLink
            to="/payments"
            text="Pagamenti Fatture"
            background="#50C254"
            icon="Payments"
          />
          <Typography variant="h6" gutterBottom component="div" className="grid-padding">
            Qui potrai visualizzare lo stato dei pagamenti (aggiornati in tempo reale solo se effettuati online) ed effettuare il saldo per ogni prenotazione. È possibile inoltre scaricare tutta la documentazione fiscale.
          </Typography>
        </GridItem>

        <GridItem id="tour-step-6">
          <CardLink
            to="/documents"
            text="Contratti Documenti"
            background="#E91E63"
            icon="Documents"
          />
          <Typography variant="h6" gutterBottom component="div" className="grid-padding">
            Qui potrai visualizzare e scaricare tutta la documentazione emessa dalla Giocamondo e caricare a tua volta  i documenti richiesti (es. riepilogo prenotazione, contratto,ecc.)
          </Typography>
        </GridItem>
      </Container>

      {/**
     * A77 richiesta gennaio2022
     */}
      <Container>
        <ExternalLinksContainer>

          <LinkContainer>
            <StyledPaper>
              <TextContainer>
                <ImageTitle>

                </ImageTitle>
                <Typography variant="h5">
                  LEZIONI ONE TO ONE ED ESAMI DI CERTIFICAZIONE
                </Typography>
                <Typography variant="body2">
                Migliora il tuo inglese e conferma le tue competenze linguistiche con certificati riconosciuti dal MIUR
                </Typography>
                <MyLink href={"/onetoone"} underline="none">
                 SCOPRI DI PIÙ
                </MyLink>
              </TextContainer>

            </StyledPaper>
          </LinkContainer>

          <LinkContainer>
              <StyledPaper>
                <TextContainer>
                  <ImageTitle>

                  </ImageTitle>
                  <Typography variant="h5">
                  GIOCAMONDO STORE
                  </Typography>
                  <Typography variant="body2">
                  Continua i tuoi acquisti con Giocamondo, gadget, felpe, libro, e tanti altri articoli designed by Giocamondo
                  </Typography>
                  <MyLink href={"/store"} underline="none">
                  SCOPRI DI PIÙ
                  </MyLink>
                </TextContainer>
              </StyledPaper>
          </LinkContainer>

        </ExternalLinksContainer>
      </Container>

     {/*
      <Container>
        <ExternalLinksContainer>

          <LinkContainer>
            <StyledPaper>
              <Link href="https://app.lessonlive.it/account/register/">
                <ImageLink
                  src={require('../assets/LessonLive_iorestoacasa.png')}
                  alt="Lezioni di Inglese a casa tua"
                />
              </Link>

              <TextContainer>
                <ImageTitle>
                Resta a casa, ma resta motivato!
                </ImageTitle>
                <Typography variant="h6">
                Con tutto il cuore, per aiutarti a restare attivo e propositivo anche in questo momento di difficoltà
                </Typography>
                <Typography variant="body2">
                noi di <strong>Giocamondo Study e Lesson Live</strong> vogliamo supportarti con <strong><u>6 lezioni gratuite di inglese one-to-one ed insegnanti madrelingua, del valore commerciale di 74€</u></strong>. Questa opportunità è per i primi <strong>200 richiedenti e iscritti a GiocamondoClub</strong>. Le lezioni sono da effettuare entro il 30 Aprile 2020. <br/>
                Come fare? <ul><li>1) Clicca sotto su: Inizia Subito </li>
                <li>2) Registrati a Lesson Live </li>
                <li>3) Invia una mail a <a href="mailto:info@lessonlive.it?subject=Richiesta lezioni gratuite Lesson Live">info@lessonlive.it</a> richiedendo le lezioni gratuite indicando nella mail il tuo nome e cognome.</li></ul> <br />
                  #IORESTOACASA
                </Typography>
              </TextContainer>

              <LinkButtonLL href="https://app.lessonlive.it/account/register/" />
            </StyledPaper>
          </LinkContainer>

          <LinkContainer>
            <StyledPaper>
              <Link href="https://www.giocamondostudy.it/vacanze-studio-estero/">
                <ImageLink
                  src={require('../assets/GSViaggiesclusivi2020.png')}
                  alt="Anteprima vacanze studio"
                />
              </Link>

              <TextContainer>
                <ImageTitle>
                  In esclusiva per te le Vacanze Studio all'Estero Giocamondo
                  Study - Conformi Estate INPSieme 2020
                </ImageTitle>
                <Typography variant="h6">
                  Sei pronto per le Vacanze Studio all'estero migliori di
                  sempre?
                </Typography>
                <Typography variant="body2">
                  Nuove incredibili destinazioni, escursioni mozzafiato ed
                  esperienze uniche ti aspettano insieme a tantissimi nuovi
                  amici da tutto il mondo! <br />
                  #TimeToExplore
                </Typography>
              </TextContainer>

              <LinkButton href="https://www.giocamondostudy.it/vacanze-studio-estero/" />
            </StyledPaper>
          </LinkContainer>

          <LinkContainer>
            <StyledPaper>
              <Link href="https://www.lamiaestate.it/soggiorni-inpsieme/">
                <ImageLink
                  src={require('../assets/LME_Vantaggiesclusivi2020.png')}
                  alt="Anteprima vacanze soggiorni"
                />
              </Link>

              <TextContainer>
                <ImageTitle>
                  In esclusiva per te i soggiorni Estivi Giocamondo La Mia
                  Estate - Conformi Estate INPSieme 2020
                </ImageTitle>
                <Typography variant="h6">
                  Sei pronto per le Vacanze Tematiche più belle di sempre?
                </Typography>
                <Typography variant="body2">
                  Nuove incredibili destinazioni, escursioni mozzafiato ed
                  esperienze uniche ti aspettano insieme a tantissimi nuovi
                  amici!
                  <br />
                  #ChiViaggiaImpara
                </Typography>
              </TextContainer>
              <LinkButton href="https://www.lamiaestate.it/soggiorni-inpsieme/" />
            </StyledPaper>
          </LinkContainer>

        </ExternalLinksContainer>
      </Container>
      */ }
    </>
  );
};

Dashboard.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default Dashboard;

const Container = styled(Grid).attrs({container: true})`
  padding: 32px;
  max-width: 1620px;
  margin: auto;
`;

const GridItem = styled(Grid).attrs({item: true, md: 3, sm: 6, xs: 12})``;

const GridItemLarge = styled(Grid).attrs({item: true, md: 6, sm: 6, xs: 12})``;

const TextContainer = styled.div`
  padding: 16px;
`;

const ImageTitle = styled(Typography).attrs({
  variant: 'h3',
})`
  margin-bottom: 16px;
`;

const LinkButton = styled(Button).attrs({
  text: 'SCOPRI LE NOVITÀ 2020',
  startIcon: <ArrowForward color="secondary" />,
  color: 'primary',
  variant: 'contained',
  disableElevation: true,
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  position: absolute;
  right: 12px;
  bottom: -20px;
  color: ${props => props.theme.palette.common.white};
`;

const LinkButtonLL = styled(Button).attrs({
  text: 'INIZIA SUBITO',
  startIcon: <ArrowForward color="secondary" />,
  color: 'warning',
  variant: 'contained',
  disableElevation: true,
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  width: 265px;
  position: absolute;
  right: 12px;
  bottom: -20px;
  background: #ff6700;
  color: ${props => props.theme.palette.common.white};
`;

const LinkContainer = styled(Grid).attrs({
  item: true,
  sm: 4,
  xs: 12,
  justify: 'center',
  container: true,
})`
  margin-bottom: 32px;
`;

const Link = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})``;

const ExternalLinksContainer = styled(Grid).attrs({
  container: true,
  justify: 'space-around',
  alignItems: 'stretch',
})`
  margin: 0 auto 64px;
  /*max-width: 1200px;*/
`;

const StyledPaper = styled(Paper)`
  margin: 8px 20px;
  height: 100%;
  position: relative;
`;

const ImageLink = styled.img`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
`;

const IFrameButton = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
})`
  display: block;
  margin: 24px 0;
`;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Chip, Typography} from '@material-ui/core';

import {useUser} from '../utils/UserSession';
import {
  CLUSTER_CODE_BLUE,
  CLUSTER_CODE_SILVER,
  CLUSTER_CODE_GOLD,
  CLUSTER_CODE_DIAMOND,
} from '../utils/constants';

const chipColorMap = {
  [CLUSTER_CODE_BLUE]: '#20aaea',
  [CLUSTER_CODE_SILVER]: '#c0c0c0',
  [CLUSTER_CODE_GOLD]: '#fff2ad',
  [CLUSTER_CODE_DIAMOND]: '#dde6ec',
};

const PointsBox = ({isDocente}) => {
  const {user} = useUser();
  const {points, clusterCode} = user;

  return (
    <Container>
      {!isDocente && <Text>Punti accumulati</Text>}
      {!isDocente && <NumberText id="tour-step-1">{points}</NumberText>}
      {clusterCode && !isDocente && (
        <Badge
          id="tour-step-2"
          background={chipColorMap[clusterCode] || '#20aaea'} //default blue
          label={clusterCode.toUpperCase()}
        />
      )}
    </Container>
  );
};

PointsBox.propTypes = {
  points: PropTypes.number,
  clusterCode: PropTypes.string,
};

export default PointsBox;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${props => props.theme.palette.primary.main};
`;

const Badge = styled(Chip)`
  height: 20px;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.palette.common.white};
  background-color: ${props => props.background};
`;

const Text = styled(Typography).attrs({
  component: 'h6',
  variant: 'h6',
  color: 'secondary',
})`
  font-size: 17px;
  font-weight: 500;
`;

const NumberText = styled(Typography).attrs({
  component: 'h3',
  variant: 'h3',
  color: 'secondary',
})`
  font-weight: bold;
  font-size: 48px;
`;
